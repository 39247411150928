import React, { Component } from "react";
import {FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import BackButton from "../component/header/BackButton";

const topBannerPictureBackground = {
    backgroundImage: 'url(/assets/images/photos/dsst-03-1920x600.jpg)',
    // backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundPosition: 'center top',
}

class DsstDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
                <BackButton />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100"  style={ topBannerPictureBackground }  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">Digita Service Strength Tester</h2>
                                    <p>Interactive engagement developed for Dreamforce.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/dsst-06-687x777.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">What</span>
                                                        <h2 className="title">Overview</h2>
                                                        <p className="description">Inspired by the nostalgic charm of the vintage strength tester arcade game, we crafted an interactive experience aimed at promoting special services available to Salesforce customers.</p>
                                                        <p className="description">This was a project I worked on during my time at Vibrant Labs. We were commissioned by Salesforce to develop an interactive engagement for Dreamforce, their annual conference held in downtown San Francisco. This project was smaller in scale, but it came out well.</p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <li>ReactJS</li>
                                                        <li>ExpressJS</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="thumb mb--30">
                                            {/* <img src="/assets/images/photos/dsst-04-1920x1280.jpg" alt="Portfolio Images"/> */}
                                            <img src="/assets/images/photos/dsst-05-1920x1280.jpg" alt="Digital Service Strength Tester video"/>
                                            <ModalVideo channel='vimeo' isOpen={this.state.isOpen} vimeo={{maxwidth: 1920, width:true}} videoId='928162217' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <div className="details pb--80">
                                                {/* <span className="subtitle">Our service</span>
                                                <h2 className="title">Providing Service</h2> */}
                                                <p className="description">Conference attendees were invited to participate by answering a series of questions. Based on their responses, the game awarded them a unique digital badge reflecting their utilization of Salesforce services, accompanied by personalized feedback for improvement.</p>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Development</span> */}
                                                        {/* <h2 className="title">Providing Service</h2> */}
                                                        <p className="description">To bring this concept to life, we collaborated closely with Salesforce's graphic design team. Our goal was to understand their initial static visual design and enhance it with engaging animations and interactive elements, effectively elevating the overall user experience</p>
                                                        <p className="description">Because this installation was primarily screen-based, it enabled us to run two setups consecutively, effectively doubling the traffic during peak foot traffic periods.</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/DSST_animation_prev-02.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/dsst_input-687x777.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p className="description">For development, I chose to build a full-screen web application using ReactJS with a very simple backend using ExpressJS.</p>
                                                    <p className="description">A crucial requirement from Salesforce was the collection of visitors' email addresses or phone numbers to deliver their results and facilitate post-event follow-ups. This was seamlessly achieved using the Twilio API for text messaging and SendGrid for email integration, before the data was securely stored in a database.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/photos/dsst-04-1920x1280.jpg" alt="Portfolio Images"/>
                                        </div>
                                        {/* End Single Area */}
                                        <div className="details mt--100 pb--80">
                                                {/* <span className="subtitle">Our service</span>
                                                <h2 className="title">Providing Service</h2> */}
                                            <p className="description">While this project was brief, it was rewarding and was well received by Salesfoece representatives as well as the conference attendees!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default DsstDetails;