import React from 'react';
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const BackButton = (props) => {

    return (
        <HashLink to="/#projects">
            {/* <div style={{position: 'absolute', zIndex: 10, background: 'white', width: 'auto', height: '30px', left: '10px', top: '10px', borderRadius:14}}> */}
            <div className='back-btn'>
                <div>
                    <FiChevronLeft className='position-relative back-chevron'/>
                    <span className='position-relative text-black'>Back</span>
                </div>
            </div>
        </HashLink>
        
    );
};

export default BackButton;