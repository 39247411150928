import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/photos/digital_whiteboard-390x532-01.jpg" alt="nSight Surgical" />;
const Portfolio_image2 = <img src="/assets/images/photos/dsst-04-390x532.jpg" alt="Digital Service Strength Tester" />;
const Portfolio_image3 = <img src="/assets/images/photos/stair-aware-product-390x532.jpg" alt="Stair Aware" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="React Creative Agency" />;

const PortfolioListContent = [
    {
        image: Portfolio_image3,
        category: 'Engineering/Design',
        title: 'Stair Aware',
        description: 'Stair Aware aims to enhance stair safety while adding aesthetic appeal to existing architecture.',
        link: '/stair-aware-details'
    },
    {
        image: Portfolio_image,
        category: 'Hardware Prototyping/Frontend Dev',
        title: 'nSight Surgical',
        description: 'Computer vision assisted safety and efficiency platform for the OR.',
        link: "/nsight-details"
    },
    {
        image: Portfolio_image2,
        category: 'Frontend Development',
        title: 'Digital Service Strength Tester',
        description: 'Custom interactive engagement for Dreamforce.',
        link: '/dsst-details'
    }
    
    // {
    //     image: Portfolio_image4,
    //     category: 'Stair Aware',
    //     title: 'Website Design',
    //     description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
    //     link: '/portfolio-details'
    // },
    // {
    //     image: Portfolio_image5,
    //     category: 'Application',
    //     title: 'Digital Service Strength Tester',
    //     description: 'Digital activation for Dreamforce converence in San Francisco.',
    //     link: '/portfolio-details'
    // },
    // {
    //     image: Portfolio_image6,
    //     category: 'Photoshop',
    //     title: 'Photo Editing',
    //     description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
    //     link: '/portfolio-details'
    // }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.link}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.link}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.link}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.link}></Link>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default PortfolioList;