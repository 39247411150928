import React, { Component } from "react";
import {FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import BackButton from "../component/header/BackButton";


import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo";

const backgroundStyle = {
    backgroundImage: 'url(/assets/images/photos/nsight_banner_static.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center top',
}

class NSightDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }

    
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
                <BackButton />


                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 " style={ backgroundStyle } data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">nSight Surgical Inc</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/nsight_vimeo_no-logo.png" alt="Service Images"/>
                                                    <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='883139008' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">Mission</span>
                                                        <h2 className="title">My work at nSight</h2>
                                                        <p className="description">
                                                            nSight utilizes AI and Computer Vision to improve safety and efficiency in the opperating room.
                                                        </p>
                                                        <p className="description">
                                                            My contributions were primarily focused on two key areas within the project scope.
                                                        </p>
                                                        <p>
                                                            <ul className="description">
                                                                <li className="description">
                                                                    I spearheaded the design and prototyping of the hardware to be installed in each 
                                                                    operating room.
                                                                </li>
                                                                <li className="description">
                                                                    I assumed a central role in the development of the frontend software interface for the nSight Digital Whiteboard.
                                                                </li>
                                                            </ul>
                                                        </p>
                                                        
                                                        {/* <p className="description">
                                                            First, I spearheaded the design and prototyping of the hardware system to be installed in each 
                                                            operating room. This involved meticulously sourcing and evaluating components that aligned within our tight requirements, 
                                                            which were then integrated with custom-manufactured fixtures designed to adhere to the exacting standards of operating 
                                                            room guidelines.
                                                        </p> */}
                                                        <p className="description">
                                                            
                                                        </p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                        <li>Risus commodo viverra</li>
                                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Hardware Prototyping</span>
                                                        <h2 className="title">nSight Digital Whiteboard</h2>
                                                        <p className="description">
                                                            The Digital Whiteboard serves as the central interface for surgical staff interacting with the nSight platform, 
                                                            effectively replacing the traditional physical whiteboard commonly found in operating rooms. Leveraging our computer vision system, the digital whiteboard 
                                                            facilitates both manual and autonomous counts, precise surgical phase timing, and enables staff to review critical 
                                                            moments during surgery or locate misplaced items swiftly.
                                                        </p>
                                                        <p className="description">  
                                                            Through rigorous 
                                                            research and user testing, we meticulously replicated all functionalities of a physical whiteboard while enhancing 
                                                            it with additional features based on user needs. 
                                                        </p>
                                                        <p className="description">  
                                                            We chose to use a large expansive screen to ensure visibility from all areas of the operating room. The digital 
                                                            whiteboard alleviates another significant issue with handwritten whiteboards by providing a standardized, legible 
                                                            interface, eliminating confusion caused by varying handwriting styles. Furthermore, it offers a universal interface, 
                                                            fostering familiarity among caregivers and streamlining communication processes. By enabling more effective and efficient 
                                                            care delivery, it enhances patient safety and overall quality of care in hospital settings.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/digital_whiteboard-01.jpg" alt="Digital Whiteboard in OR"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/nsight _detector_demo-01.png" alt="Object Detector Demo"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Hardware Prototyping</span>
                                                        <h2 className="title">Camera Network</h2>
                                                        <p className="description">
                                                            Ensuring precision within our computer vision platform was paramount, thus our camera selection process adhered to stringent criteria. As the lead in this domain, I undertook the responsibility of sourcing cameras that not only met our very specific requirements but also aligned with our budgetary considerations. This involved extensive research and evaluation of a wide array of off-the-shelf options, while also accounting for factors such as connectivity and power management within the physical constraints mandated by hospital guidelines.
                                                        </p>
                                                        <p className="description">
                                                            My role extended beyond procurement, as I orchestrated a series of rigorous tests in collaboration with our computer vision team to determine the requisite features and specifications essential for achieving our objectives. Subsequently, I ensured that our chosen hardware could seamlessly support up to eight concurrent high-resolution camera streams to the computer vision server, guaranteeing reliability under operational conditions.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <p className="description">
                                                    I worked closely with the mechanical design team in the development of a bespoke mounting fixture tailored to our specific requirements. This fixture not only seamlessly and securely mounts our camera network within the operating room environment but also meticulously complies with the stringent guidelines set forth by hospital regulations. Through meticulous attention to detail and strategic collaboration across interdisciplinary teams, I contributed to the seamless integration of our computer vision system, bolstering its effectiveness and reliability within clinical settings.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/camera_network-02.jpg" alt="Camera Network Image"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/photos/nsight_frontend-01.jpg" alt="nSight Frontend Image"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Software</span>
                                                        <h2 className="title">Frontend Development</h2>
                                                        <p className="description">
                                                        I assumed a central role in the development of the frontend software interface for the nSight Digital Whiteboard. 
                                                        </p>
                                                        <p className="description">
                                                        Collaborating closely with our visual designer, I took the lead in guiding a team of developers through this process. Leveraging my expertise in both interaction design along with development, I facilitated seamless communication between the design and development teams, ensuring that the design intent and user experience were meticulously honored throughout the development process.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Pricing Tbale Area  */}
                {/* <div class */}
                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Free</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Business</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">$99</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}
                        {/* </div>
                    </div>
                </div> */}
                {/* End Pricing Tbale Area  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo/>

            </React.Fragment>
        )
    }
}
export default NSightDetails;