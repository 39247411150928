import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import ModalVideo from 'react-modal-video';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderTwo from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo"
import FooterThree from "../component/footer/FooterThree";
import BrandTwo from "../elements/BrandTwo";
import SkillBar from "../blocks/ProgressBar";
import ProgressOne from "../blocks/progressbar/ProgressOne";
import ProgressTwo from "../blocks/progressbar/ProgressTwo";
import SliderThree from "../component/slider/SliderThree";
import SliderOne from "../component/slider/SliderOne";
import SliderTwo from "../component/slider/SliderTwo";
import SliderWithVideo from "../component/slider/SliderWIthVideo";
// import sliderBGImage from "/assets/images/photos/colin_profile-2.png";

import PortfolioList from "../elements/portfolio/PortfolioList";
import ContactOne from "../elements/contact/ContactOne";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Design Engineer',
        title :"Hi, I’m <span>Colin.</span>",
        // description: `I'm committed to using my diverse set of skills to make a positive difference.`,
        buttonText: 'Download Portfolio',
        buttonLink: '',
        style:  { 
            background: 'url(/assets/images/photos/colin_profile_lab-02.jpg)' ,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            
        }
    }
]
class PersonalPortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var namesItemOne = [
            'Front-End Development',
            'Hardware Prototyping',
        ];
        let title = 'Summary',
        description = `With over 9 years of hands-on experience in hardware/software design, prototyping, and development, I'm a forward-thinking Design Engineer. I specialize in crafting user-friendly interfaces and driving product innovation, excelling in bridging the gap between design and engineering disciplines. My 'T-shaped' skill set empowers me to swiftly identify creative solutions, making me an adaptable problem solver. I take pride in my ability to explore optimal approaches through rapid prototyping methodologies, consistently delivering results that exceed expectations.
        A collaborative team player with excellent communication skills, I thrive in interdisciplinary environments and am dedicated to achieving project objectives with precision and efficiency.
        I invite you to explore my portfolio to experience how my unique blend of skills and experience can elevate your projects to new heights.`;
        
        return(
            <Fragment> 
                <Helmet pageTitle="Personal Portfolio" />
                
                {/* <HeaderTwo logo="symbol-dark" color="color-black"/> */}
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className={`slide slider_fixed_height slider-style-3 d-flex align-items-center justify-content-center white-overlay-mobile`} style={value.style} key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span className="theme-gradient font-500" >{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description" dangerouslySetInnerHTML={{__html: value.description}}></p> : ''}
                                            {/* {value.buttonText ? <div className="slide-btn"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}
                {/* <SliderTwo /> */}
                {/* Start About Area */}
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/photos/colin_profile-1.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">Let me introduce myself</span>
                                            <h2 className="title">{title}</h2>
                                            <p className="description">With over 9 years of hands-on experience in hardware/software design, prototyping, and development, I'm a forward-thinking Design Engineer. I specialize in crafting user-friendly interfaces and driving product innovation, excelling in bridging the gap between design and engineering disciplines. </p>
                                            <p className="description">My 'T-shaped' skill set empowers me to swiftly identify creative solutions, making me an adaptable problem solver. I take pride in my ability to explore optimal approaches through rapid prototyping methodologies, consistently delivering results that exceed expectations.</p>
                                            <p className="description">A collaborative team player with excellent communication skills, I thrive in interdisciplinary environments and am dedicated to achieving project objectives with precision and efficiency.</p>
                                            <p className="description">I invite you to explore my portfolio to experience how my unique blend of skills and experience can elevate your projects to new heights.</p>
                                        </div> 
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Education</h5>
                                                    <p>B.A. in Interaction Desogn from Californnia College of the Arts.</p>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">My Process</h5>
                                                    <p>Lorem ipsum dolor sit amet, onecis et mollis.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Development Skill</h5>
                                                    <p>I have experience writing software in different languages for over 10 years.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">My Superpower</h5>
                                                    <p>I have a background in design, with a strong engineering mindset. I excel at aligning the creative with practical implementation and ensuring a seamless integration of design and engineering.</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area  */}
                {/* <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25 mb_sm--0">
                                    <span className="subtitle">My Top clients</span>
                                    <h2 className="title">Clients Meeting With Me</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--30">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-10.png" alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="row pt--120">
                            <div className="col-lg-12">
                                <BrandTwo branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}

                {/* Start Skill Area  */}
                <SkillBar />
                {/* End Skill Area  */}

                {/* Start Most Recent Work Area  */}
                {/* <div className="rn-about-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Most Recent Work</span>
                                        <h2 className="title">nSight Surgical</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim exercitationem impedit iure quia quo recusandae? adipisicing elit. Enim exercitationem impedit iure quia quo recusandae?</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim exercitationem impedit iure quia quo recusandae?</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="about-button mt--30">
                                        <a className="btn-default" href="/about">See My Skill</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Most Recent Work Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1" id="projects">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30 mb_sm--0">
                                        <span className="subtitle">Check it out</span>
                                        <h2 className="title">Work Examples</h2>
                                        <p className="description">Take a look at some of the things I have worked on.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="btn-default" href="/blog"><span>View Projects</span></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1" >
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;