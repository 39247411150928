import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressTwo = (props) => {
    console.log('props.content[0]', props.content);
    return (
        // Start Single Progressbar 
        <div className={`rn-progress-bar ${props.ProgressStyle}`}>

            {props.content.map((skillContent) => {
                return(
                    <div className="single-progress custom-color--1">
                        <h6 className="title">{skillContent.name}</h6>
                        <ProgressBar now={skillContent.value} key={skillContent.name} />
                        <span className="label">{skillContent.value}%</span>
                    </div>
                )
            })}
        </div>
        // {/* // End Progress Bar */}
    )
}

export default ProgressTwo
